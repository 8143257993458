@tailwind base;
@tailwind components;

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

html {
    overflow: hidden;
}

body {
    height: 100%;
    width: 100vw;
    position: fixed;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

@tailwind utilities;